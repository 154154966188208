/*  $program_details

    Purpose: Set layout of sibling els in on program
    pages + add <button> control for toggle
    ================================================== */
(function program_details() {
    if ($('.program-details').length > 0) {
        const pd_wrapper = $('.program-details__wrapper');
        const pd_container = $('.program-details__container');
        const pd = $('.program-details');
        const focusableEls = pd.find('a, [tabindex], .c-info-badge');
        const mq_mobile = window.matchMedia('(max-width:767px)');
        const mq_tablet = window.matchMedia('(min-width:768px)');

        // layout
        const programDetailsLayout = function () {
            const mb_container = $('.magic-box__container');
            const mb_container_v2 = $('.image-box__container');

            // viewport check: tablet +
            if (mq_tablet.matches) {
                const pd_height = pd[0].scrollHeight;
                const mb_height = $('.magic-box').outerHeight();

                // if not program page v2
                if (mb_container_v2.length === 0) {
                    // if no expander present, add one
                    if (!$('.program-details__expander').length) {
                        pd_container.append('<button class="btn program-details__expander display--block"'
                            + ' aria-controls="program-details" aria-expanded="false" id="program-details--expander">'
                            + '<span>Show more</span></button>');
                    }

                    const pd_expander = $('.program-details__expander');

                    // .program-details taller than 350px AND taller than .magic-box
                    if (pd_height > 350 && pd_height > mb_height) {
                        pd_wrapper
                            .removeClass('content-is--exposed')
                            .addClass('content-is--clipped');

                        pd.css('height', mb_height + 20);

                        pd_expander
                            .attr('aria-expanded', 'false')
                            .find(' > span').text('Show more');
                    } else { // (pd_height <= mb_height) | .program-details shorter than .magic-box
                        pd_wrapper.removeClass('content-is--clipped');

                        pd.removeAttr('style');

                        pd_expander.remove();
                    }
                }
            }

            // viewport check: mobile (767 and under)
            // move Magic Box inside Program Details per design
            if (mq_mobile.matches) {
                $('.program-details__expander').remove();
                mb_container.insertAfter('#program-details .program-details__core-data');
                mb_container_v2.insertAfter('#program-details-v2 .program-details__core-data');
                pd_wrapper.removeClass('content-is--clipped content-is--exposed');
                pd.removeAttr('style');
            } else {
                mb_container.insertAfter('.program-details__container');
                mb_container_v2.appendTo('#program-image-slot-v2');
            }

            // DOM changes complete, expose .program-details__wrapper (do this regardless)
            pd_wrapper.addClass('is-visible');
        };// end layout

        // behavior
        const programDetailsBehavior = function () {
            const mb_height = $('.magic-box').outerHeight();

            $('.program-details__expander')
                // eslint-disable-next-line no-unused-vars
                .on('click', (event) => {
                    if ($('.program-details__expander').is('[aria-expanded="true"]')) {
                        pd_wrapper
                            .removeClass('content-is--exposed')
                            .addClass('content-is--clipped')
                            .scrollTop(0);

                        if (mq_tablet.matches) {
                            pd
                                .css('height', mb_height + 20)
                                .scrollTop(0);
                        }

                        $('.program-details__expander')
                            .attr('aria-expanded', 'false')
                            .find(' > span').text('Show more');
                    } else {
                        pd_wrapper
                            .removeClass('content-is--clipped')
                            .addClass('content-is--exposed');

                        pd.removeAttr('style');

                        $('.program-details__expander')
                            .attr('aria-expanded', 'true')
                            .find('> span').text('Show less');
                    }
                });
        };// end behavior

        // accessibility: ensure that any focusable el inside
        // .program-details will trigger expander if focus
        // event occurs
        const accessibleProgramDetails = function () {
            if ($('.program-details__expander').length) {
                focusableEls.focus(() => {
                    pd_wrapper
                        .removeClass('content-is--clipped')
                        .addClass('content-is--exposed')
                        .scrollTop(0);

                    pd
                        .removeAttr('style')
                        .css('overflow', 'visible')
                        .scrollTop(0);

                    $('.program-details__expander')
                        .attr('aria-expanded', 'true')
                        .find('> span').text('Show less');
                });
            }
        };

        // clean author supplied markup
        const cleanDescriptionData = function () {
            const dd = $('.program-details__def');

            dd.each(function () {
                const el = $(this);
                if (el.children().length > 0) {
                    el
                        .find('br, hr').remove().end()
                        // eslint-disable-next-line max-len
                        .find('*:not("a"):not("b"):not("strong"):not("i"):not("em"):not("sup"):not("sub"):not("button"):not("span"):not("div")')
                        .contents()
                        .unwrap();
                }
            });
        };

        // call both fns on win.load
        $(window).on('load', () => {
            cleanDescriptionData();
            programDetailsLayout();
            programDetailsBehavior();
            accessibleProgramDetails();
        });

        // call layout fn on debounced resize
        $(window).smartresize(() => {
            programDetailsLayout();
        });
    }// end if .program-details exists check
}());// end program_details()
