/** ###########################################################################################################
    ###########################################################################################################

    This function is wrapper around jQuery UI autocomplete plugin.

    Dependency:
      - jQuery UI ( and its dependencies )
      - sanitizer.js ( provided in same directory as this file )

    Needs options object passed as argument with following items:

    @param {Object} options - options for autocomplete data
    @param {jQuery Object} options.inputField - Input field to attach autocomplete to
    @param {number} options.delay - Number of seconds before autocomplete search is triggered
    @param {number} options.minLength - Number of characters before autocomplete search is triggered
    @param {string} options.autocompleteUrl - Autocomplete endpoint to fetch data from
    @param {string} options.encodedContextPath - Context path for results URL
    @param {string} options.noResultsFallback - Text for no result returned

    // Example of options object
    var autocompleteOptions = {
      inputField: $('.search-box-input'),
      delay: 500,
      minLength: 3,
      autocompleteUrl: '/search/autocomplete/SearchBox',
      encodedContextPath: '/store/',
      noResultsFallback: 'No item found'
    };
*/

const autocompleteUtils = {
    defaultPostalCode: '0000',
    markSearchBoxValid() {
        $('.address-search').removeClass('has-error');
        $('.address-search .help-block').addClass('hidden');
    },
    insertLineBreakAfterName(selectedAddress) {
        if (!selectedAddress.firstName && !selectedAddress.lastName && !selectedAddress.title) {
            return '';
        }

        return '<br>';
    },
    insertCommaBeforeState(selectedAddressState) {
        if (!selectedAddressState) {
            return '';
        }
        return ', ';
    },
    insertAddressLine3(addressLine3)
    {
       return !addressLine3 ? '' : '<li><p>' +addressLine3+ '</p></li>'
    },
    insertLineState(selectedAddressState) {
        if (!selectedAddressState) {
            return '';
        }
        return '<li><p>' + selectedAddressState + '</p></li>';
    },
    insertAddressLines(line1, line2, line3) {
        if (line3) {
            if(line2) {
                return line1  + line2  + line3;
            } else {
                return line1  + line3;
            }
        } else {
            if(line2) {
                return line1  + line2;
            } else {
                return line1;
            }
        }
    },
    getValue(value) {
        return value !== null && value !== 'null' ? value : '';
    },
    getAddressLineWithoutPostalCode(
        selectedAddressTitle,
        selectedAddress,
        selectedAddressLine1,
        selectedAddressLine2,
        selectedAddressState,
        selectedAddressEmail,
        selectedAddressPhone,
        selectedAddressLine3,
        selectedAddressTown,
    ) {
        if(!ACC.config.isAssessmentsCheckoutV2Enabled) {
            $('.address-search .help-block').after(
                `<ul class="address">${selectedAddressTitle} ${selectedAddress.firstName} ${
                    selectedAddress.lastName}${autocompleteUtils.insertLineBreakAfterName(selectedAddress)
                }${selectedAddressLine1}${selectedAddressLine2
                }${selectedAddressLine3}<br>${selectedAddressTown
                }${selectedAddressState}<br>${
                    selectedAddress.country}<br>${
                    selectedAddressEmail
                }${selectedAddressPhone}</ul>`,
            );
        } else {
         selectedAddressLine1 = selectedAddressLine1.trimEnd().concat(", ")
         if(!ACC.config.isAddressLine3MultiLineEnabled){
            $('.address-search .help-block').after(
                `<ul class="address">
                    <li><p>${autocompleteUtils.insertAddressLines(selectedAddressLine1,selectedAddressLine2,selectedAddressLine3)}</p></li>
                    <li><p>${selectedAddressTown}${selectedAddressState}</p></li>
                    <li><p>${selectedAddress.country}</p></li>
                </ul>`,
            );
         }
         else{
            $('.address-search .help-block').after(
                `<ul class="address">
                    <li><p>${selectedAddressLine1}${selectedAddressLine2}</p></li>
                    <li><p>${selectedAddressLine3}</p></li>
                    <li><p>${selectedAddressTown}${selectedAddressState}</p></li>
                    <li><p>${selectedAddress.country}</p></li>
                </ul>`,
            );
         }
        }
    },
    getAddressLineWithPostalCode(
        selectedAddressTitle,
        selectedAddress,
        selectedAddressLine1,
        selectedAddressLine2,
        selectedAddressState,
        selectedAddressEmail,
        selectedAddressPhone,
        selectedAddressLine3,
        selectedAddressTown,
    ) {
        if(!ACC.config.isAssessmentsCheckoutV2Enabled) {
            $('.address-search .help-block').after(
                `<ul class="address">${selectedAddressTitle} ${selectedAddress.firstName} ${
                    selectedAddress.lastName}${autocompleteUtils.insertLineBreakAfterName(selectedAddress)
                }${selectedAddressLine1}${selectedAddressLine2
                }${selectedAddressLine3}<br>${selectedAddressTown
                }${selectedAddressState}<br>${selectedAddress.zip}<br>${
                    selectedAddress.country}<br>${
                    selectedAddressEmail
                }${selectedAddressPhone}</ul>`,
            );
        } else {
         selectedAddressLine1 = selectedAddressLine1.trimEnd().concat(", ")
         if(!ACC.config.isAddressLine3MultiLineEnabled){
            $('.address-search .help-block').after(
                `<ul class="address">
                    <li><p>${autocompleteUtils.insertAddressLines(selectedAddressLine1,selectedAddressLine2,selectedAddressLine3)}</p></li>
                    <li><p>${selectedAddressTown}${selectedAddressState}${selectedAddress.zip}</p></li>
                    <li><p>${selectedAddress.country}</p></li>
                </ul>`,
            );
         }
         else{
            $('.address-search .help-block').after(
                `<ul class="address">
                    <li><p>${selectedAddressLine1}${selectedAddressLine2}</p></li>
                     ${autocompleteUtils.insertAddressLine3(selectedAddressLine3)}
                    <li><p>${selectedAddressTown}${selectedAddressState}${selectedAddress.zip}</p></li>
                    <li><p>${selectedAddress.country}</p></li>
                </ul>`,
            );
         }
        }
    },
};

// eslint-disable-next-line no-unused-vars
function addAutocomplete(options) {
    // Custom "_renderItem" method
    // eslint-disable-next-line consistent-return,no-underscore-dangle
    function _helperRenderItem(ul, item) {
        const minNumberOfAddresses = $('.address-search').data('min-number-of-addresses');
        let renderHtml;

        if (item.type === 'addressData') {
            let firstAndLastName;
            let addressInfo;

            if (ACC.config.isAssessmentsCheckoutV2Enabled) {
                firstAndLastName = '';
            } else {
                firstAndLastName = (`${item.firstName} ${item.lastName}`).trim();
            }
            if (ACC.config.addressDoctorFlipLines) {
                if (ACC.config.countriesMissingPostalCode.indexOf(item.countryIsoCode) >= 0) {
                    addressInfo = [firstAndLastName, item.line1,
                        item.line2, item.line3, item.town, item.stateName].filter(Boolean).join(', ');
                } else {
                    addressInfo = [firstAndLastName, item.line1,
                        item.line2, item.line3, item.town, item.stateName, item.zip].filter(Boolean).join(', ');
                }
            } else if (ACC.config.countriesMissingPostalCode.indexOf(item.countryIsoCode) >= 0) {
                addressInfo = [firstAndLastName, item.line2, item.line3,
                    item.line1, item.town, item.state].filter(Boolean).join(', ');
            } else {
                addressInfo = [firstAndLastName, item.line2, item.line3,
                    item.line1, item.town, item.state, item.zip].filter(Boolean).join(', ');
            }
            if (this.term) {
                addressInfo = addressInfo.replace(new RegExp(this.term, 'gi'), '<b>$&</b>');
            }

            renderHtml = '<div class="search-address-data">'
                + `   <p>${addressInfo}</p>`
                + '</div>';
        }

        if (item.totalNumberOfItems <= minNumberOfAddresses) {
            return $('<li>').data('item.autocomplete', item).append(renderHtml).appendTo(ul);
        }

        // In case that our returned item has no "type" attribute it means that
        // server did not returned any results, or returned empty array => []
        if (!item.type) {
            // Create a custom HTML template
            renderHtml = '<div class="search-address-data" onclick="event.stopPropagation();">'
                // And map with "noResultsFallback" options field passed when the
                // plugin is attached to field
                + `   <p>${this.options.customOptions.noResultsFallback}</p>`
                + '</div>';

            return $('<li>').data('item.autocomplete', item).append(renderHtml).appendTo(ul);
        }
    }

    // Adding this to override jQuery UI's autocomplete dropdown's width to match the input width
    // eslint-disable-next-line no-underscore-dangle
    $.ui.autocomplete.prototype._resizeMenu = function () {
        const ul = this.menu.element;
        ul.outerWidth(this.element.outerWidth());
    };

    // Throw error if there is no input field ( jQuery object ) provided
    if (!options.inputField) {
        throw new Error('Provide input element to attach autocomplete to!');
    }

    // Attach autocomplete functionality
    options.inputField.autocomplete({

        // Specify location of the UL element
        position: { my: 'left top', at: 'left bottom' },

        // Create internal custom object to store our settings/options
        customOptions: options || {},

        // Custom cache for storing phrases/results
        cache: {},

        // Number of milliseconds before search is triggered
        delay: options.delay || 500,

        // Minimum chars before search is triggered
        minLength: options.minLength || options.defaultMinLength,

        // When the autocomplete instance is created
        create() {
            // Attach custom "_renderItem" method to manipulate results HTML structure ( see end of this file )
            // eslint-disable-next-line no-underscore-dangle,no-use-before-define
            $(this).data('ui-autocomplete')._renderItem = _helperRenderItem;
        },

        // Triggered when an item is selected from the dropdown.
        select(event, ui) {
            event.preventDefault();
            const selectControl = $('#js-address-search-input');
            const isShippingAddress = selectControl.data('is-shipping-address');
            selectControl.val('');
            const selectedAddress = ui.item;
            const selectedAddressLine2 = selectedAddress.line2 ? `${selectedAddress.line2} ` : '';
            const selectedAddressLine1 = selectedAddress.line1 ? `${selectedAddress.line1} ` : '';
            const selectedAddressLine3 = selectedAddress.line3 ? `${selectedAddress.line3}` : '';
            const selectedAddressState = (selectedAddress.stateName && ACC.config.isUkSpecific === 'false')
                ? `${selectedAddress.stateName}, ` : '';
            const selectedAddressEmail = selectedAddress.email ? `${selectedAddress.email}<br>` : '';
            const selectedAddressTitle = selectedAddress.title ? selectedAddress.title : '';
            const selectedAddressPhone = selectedAddress.phone ? selectedAddress.phone : '';
            const selectedAddressTown = selectedAddress.town ? `${selectedAddress.town}, ` : '';
            const previousAddress = $('.address-search ul.address');
            $('#searchAddressForm input[name="addressId"]').val(selectedAddress.id);
            previousAddress.remove();

            autocompleteUtils.markSearchBoxValid();
            if (selectedAddress.zip === autocompleteUtils.defaultPostalCode || !selectedAddress.zip) {
                autocompleteUtils.getAddressLineWithoutPostalCode(
                    selectedAddressTitle,
                    selectedAddress,
                    selectedAddressLine1,
                    selectedAddressLine2,
                    selectedAddressState,
                    selectedAddressEmail,
                    selectedAddressPhone,
                    selectedAddressLine3,
                    selectedAddressTown,
                );
            } else {
                autocompleteUtils.getAddressLineWithPostalCode(
                    selectedAddressTitle,
                    selectedAddress,
                    selectedAddressLine1,
                    selectedAddressLine2,
                    selectedAddressState,
                    selectedAddressEmail,
                    selectedAddressPhone,
                    selectedAddressLine3,
                    selectedAddressTown,
                );
            }

            $('.search-address-error').hide();
            if (!$('#addressValidationError').hasClass('hidden')) {
                $('#addressValidationError').addClass('hidden');
            }
            if (ACC.config.billingAddressDoctorEnabled && !isShippingAddress) {
                ACC.secureacceptance.submitFormWithSearchedBillingAddress(true);
            }
        },

        // Triggered when an item is in focus while navigating with keyboard
        focus(event) {
            // Focus triggered on dropdown item ( li ) focus
            event.preventDefault();
        },

        // Defines the data to use, must be specified.
        source(request, response) {
            // Throw error if no autocomplete URL is provided
            if (!this.options.customOptions.autocompleteUrl) {
                throw new Error('Provide URL for fetching autocomplete data');
            }

            const self = this;
            let term = request.term.toLowerCase();
            const suggestionsURL = this.options.customOptions.autocompleteUrl;
            const encodedContextURL = self.options.customOptions.encodedContextPath;

            const emptyTerm = term === '';

            // empty term is valid due to onclick suggest feature
            if (!emptyTerm) {
                // C is any Unicode alphanumeric
                // dot is optional in any of the blocks
                // term that will pass this regex will be of format:
                // CC CC+ OR C CC+ OR CCC+

                const startingBlock = /^[+\a-zA-Z0-9.?]/.source;
                const followUpBlock = /(?:([\s]?[a-zA-Z0-9]{2,}\.?)+)/.source;
                const regexMatcher = `(${startingBlock}{3,}${followUpBlock})|(${startingBlock}{1,3}${followUpBlock})`;
                const matchThreeOrMore = new RegExp(regexMatcher, 'g');

                const groups = matchThreeOrMore.exec(term);

                if (groups == null) {
                    $('#ui-id-1').hide();
                    return;
                }

                [term] = groups;
            }

            // If already searched term, return from cache
            if (this.options.cache[term]) {
                // eslint-disable-next-line consistent-return
                return response(this.options.cache[term]);
            }

            // Fetch results from server
            $.getJSON(suggestionsURL, { term: request.term }, (data) => {
                // Throw error if no autocomplete URL is provided
                if (!encodedContextURL) {
                    throw new Error('No encoded context path provided');
                }

                let autoSearchData = [];
                let addresses = [];

                if (data && data.length) {
                    addresses = data.map((item) => ({
                        firstName: autocompleteUtils.getValue(item.firstName),
                        lastName: autocompleteUtils.getValue(item.lastName),
                        line3: autocompleteUtils.getValue(item.line3),
                        line2: autocompleteUtils.getValue(item.line2),
                        line1: autocompleteUtils.getValue(item.line1),
                        town: item.town,
                        state: item.region != null ? item.region.isocodeShort : '',
                        stateName: item.region != null ? item.region.name : '',
                        title: autocompleteUtils.getValue(item.title),
                        country: item.country.name,
                        countryIsoCode: item.country.isocode,
                        zip: item.postalCode,
                        id: item.id,
                        email: autocompleteUtils.getValue(item.email),
                        phone: autocompleteUtils.getValue(item.phone),
                        type: 'addressData',
                        totalNumberOfItems: data.length,
                    }));
                }

                // If server returned 0 results, or in this case and empty array => []
                // we need to create one dummy result so the plugins can detect it and
                // trigger dropdown element.
                // ( if there is no result, plugin fill not open dropdown )
                if (data.length === 0) {
                    addresses = {};
                }

                // Concat all results
                autoSearchData = autoSearchData.concat(
                    addresses,
                );

                // Stash results in temporary ( our custom ) cache
                self.options.cache[term] = autoSearchData;

                // Return search results
                return response(autoSearchData);
            }).fail(() => {
                // In case ajax call failed, throw error
                throw new Error('Server failed retrieving autocomplete data');
            });
        },

    }).focus(function () { // triggered on input field ( search ) "focus" event
    // Trigger search with no terms on input field focus
    // to trigger first address fetch from the server and
    // display dropdown with those results
        $(this).autocomplete('search', '');
    });
}
