/** ###########################################################################################################
 ###########################################################################################################

 Modals Functionality

 ----------------------------------------------------------------------------------------------------------
 Dependency:
 - Colorbox modal plugin ( v1.6.4 )

 This functionality is used as abstraction layer between any third party library for modals
 and usage of modals in our store/templates.

 That way we can easy replace any library without readjustement in all .js/.jsp files.

 ----------------------------------------------------------------------------------------------------------
 Usage:
 - Attach "data-modal-title" and "data-modal-content" attributes to button that triggers modal opening
 - Open: Inside .js trigger modal open function with clicked element passed as parameter => "$modal.open(clickedEl)"
 - Close: Inside .js trigger modal close function without any parameters => "$modal.close()""

 */

// ###########################################################################################################
// Colorbox library global settings
const COLORBOX = {
    className: 'isHidden',
    opacity: 0.85,
    close: false,
    fixed: true,
    reposition: false,
    transition: 'none',
    top: '0',
    left: '0',
    data: true,
    title() {
        // Return title element
        return '<h4 class="headline"><span class="headline-text"></span></h4>';
    },
    html() {
        // Get modal content data from element
        const htmlContent = $.colorbox.element()[0].dataset.modalContent;
        // If value has is ID of the element, return this as $ object
        if (htmlContent && htmlContent[0] === '#') {
            // Copy content with all events ( true flag in clone method )
            const contentCopy = $(htmlContent).clone(true);
            return $(contentCopy);
        }
        // Else return it as string wrapped in DIV container
        return htmlContent ? `<div><p>${htmlContent}</p></div>` : '';
    },

    onOpen() {
    },

    onLoad() {
    },

    onComplete() {
        const colorBoxModal = document.querySelectorAll('#colorbox')[0];
        const cboxContent = colorBoxModal.querySelector('#cboxContent');
        const cboxTitle = cboxContent.querySelector('#cboxTitle');
        const {modalTitle} = $.colorbox.element()[0].dataset;
        const modalCloseBtn = colorBoxModal.querySelector('#cboxClose');
        const main = document.querySelector('main');
        const modalLabelId = 'modalLabel';
        let cboxModalTitle;
        let cboxDescription;
        let focusableEl;

        // Remove some of #colorbox default styles
        colorBoxModal.style.width = '';
        colorBoxModal.style.height = '';
        // remove unnecessary elements
        if (colorBoxModal.querySelector('#cboxSlideshow')) {
            colorBoxModal.querySelector('#cboxPrevious').remove();
            colorBoxModal.querySelector('#cboxNext').remove();
            colorBoxModal.querySelector('#cboxSlideshow').remove();
        }

        if (ACC.utils.isMojoTheme()) {
            cboxModalTitle = cboxContent.querySelector('.cboxModalTitle');
            cboxDescription = cboxContent.querySelector('.cboxDescription');
            focusableEl = cboxContent.querySelector('.focusable-element');
            // prevent scroll
            document.querySelector('body').setAttribute('style', 'overflow:hidden;');

            if (!focusableEl) {
                /* global PearsonKeyboardTrap */
                const keyboardTrap = new PearsonKeyboardTrap(cboxContent);
                const focusableEls = keyboardTrap.getFocusableEls(document.querySelector('#cboxLoadedContent'));
                // set focus for close btn or first focusable element
                if (focusableEls.length) {
                    focusableEls[0].focus();

                    if ($.colorbox.arguments && !($.colorbox.arguments[0].keyboardInteraction)) {
                        colorBoxModal.classList.add('focus-first-interactive-element');

                        focusableEls[0].addEventListener('focusout', () => {
                            colorBoxModal.classList.remove('focus-first-interactive-element');
                        });

                        window.addEventListener('blur', () => {
                            colorBoxModal.classList.add('focus-first-interactive-element');
                        });
                    }
                } else {
                    modalCloseBtn.focus();
                }
            } else {
                // Initial focus is set on the first paragraph because the first interactive element is at the bottom,
                // which is out of view due to the length of the text.
                focusableEl.focus();
            }
        }

        // Set attributes for accessibility
        colorBoxModal.setAttribute('aria-labelledby', modalLabelId);
        if (cboxContent.querySelector('.auto-renew-modal__title')) {
            cboxContent.querySelector('.auto-renew-modal__title').setAttribute('id', modalLabelId);
        }

        // Hidden another content for accessibility API
        main.setAttribute('aria-hidden', 'true');
        if (cboxModalTitle) {
            colorBoxModal.setAttribute('aria-labelledby', cboxModalTitle.id);
        }

        if (cboxDescription) {
            colorBoxModal.setAttribute('aria-describedby', cboxDescription.id);
        }

        colorBoxModal.setAttribute('aria-modal', true);
        if (modalCloseBtn) {
            modalCloseBtn.setAttribute('aria-label', 'Close Dialog');
            createCloseBtnImage(modalCloseBtn);
        }

        if (ACC.utils.isLearnerTheme() || ACC.utils.isMojoTheme()) {
            // eslint-disable-next-line no-undef
            const keyboardTrap = new PearsonKeyboardTrap(cboxContent);
            const focusableEls = keyboardTrap.getFocusableEls(document.querySelector('#cboxLoadedContent'));
            // set focus for close btn or first focusable element
            if (focusableEls.length) {
                focusableEls[0].focus();
            } else {
                if (modalCloseBtn) {
                    modalCloseBtn.focus();
                }
            }

            if (ACC.utils.isMojoTheme() && modalCloseBtn) {
                modalCloseBtn.focus();
            }
        }

        // Remove title inline styles
        cboxTitle.removeAttribute('style');

        // Remove title if content is not present
        if (modalTitle) {
            // Set title if available ( attached as data attribute to clicked element )
            cboxTitle.querySelector('.headline-text').textContent = modalTitle;
        } else if ($.colorbox.arguments && typeof $.colorbox.arguments[0].title === 'string') {
            // Set title if passed as argument while calling modals library without clicked element
            cboxTitle.innerHTML = `<h4 class="headline">
              <span class="headline-text">${$.colorbox.arguments[0].title}</span>
            </h4>`;
        } else {
            // Remove title DOM NODE if title is undefined
            cboxTitle.parentNode.removeChild(cboxTitle);
        }

        // Remove styles from one of inner divs
        colorBoxModal.querySelector('#cboxMiddleRight').removeAttribute('style');
        colorBoxModal.querySelector('#cboxMiddleLeft').removeAttribute('style');

        // Remove style attribute on #cboxWrapper & #cboxContent
        colorBoxModal.querySelector('#cboxWrapper').removeAttribute('style');
        cboxContent.removeAttribute('style');
        cboxContent.querySelector('#cboxLoadedContent').removeAttribute('style');

        // Remove hidden class ( change modal state from hidden to visible )
        setTimeout(() => {
            colorBoxModal.classList.remove('isHidden');
        }, 200);
    },

    onCleanup() {
    },

    onClosed() {
        // Show another content for accessibility API
        document.querySelector('main').removeAttribute('aria-hidden');
        if (ACC.utils.isMojoTheme()) {
            document.querySelector('body').removeAttribute('style');
        }
    },
};
if (ACC.utils.isMojoTheme()) {
    COLORBOX.keyboardInteraction = true;
}

function createCloseBtnImage(node) {
    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const iconPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    );
    iconSvg.setAttribute('role', 'img');
    iconSvg.setAttribute('aria-hidden', 'true');
    iconSvg.setAttribute('focusable', 'false');
    iconSvg.setAttribute('width', '24');
    iconSvg.setAttribute('height', '24');
    iconSvg.setAttribute('viewBox', '0 0 24 24');
    iconSvg.setAttribute('fill', 'none');

    iconPath.setAttribute('d', 'M12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858Z');
    iconPath.setAttribute('fill-rule', 'evenodd');
    iconPath.setAttribute('clip-rule', 'evenodd');
    iconPath.setAttribute('fill', '#05112A');

    iconSvg.appendChild(iconPath);
    return node.appendChild(iconSvg);
}

// ###########################################################################################################
// Our functionality written as $modal class with predefined methods
// for opening and closing modal windows

// eslint-disable-next-line no-unused-vars
const $modal = (function () {
    // Open modal
    const open = function (clickedEl, content) {
        // In case of additional content that should be passed
        // exclude DOM element which brings properties such as
        let options;
        if (clickedEl === null && content) {
            // Concatenate global settings and override with our custom ( jQuery dependency )
            options = $.extend({}, COLORBOX, content);
            // and open modal with defined settings
            return $.colorbox(options);
        }

        if (clickedEl && content) {
            // Concatenate global settings and override with our custom ( jQuery dependency )
            options = $.extend({}, COLORBOX, content);
            // and open modal with defined settings
            return $(clickedEl).colorbox(options);
        }

        // In case of classic usage, just trigger modal library, attach it to DOM element
        // and pass global settings to it.
        // ( IMPORTANT: This element should have title and modal content attached as HTML 5 data attributes
        // data-modal-title and data-modal-content )
        return $(clickedEl).colorbox(COLORBOX);
    };

    // Close modal
    const close = function () {
        // Trigger modal library's internet closing method
        return $.colorbox.close();
    };

    // Return whole $modal functionality as JS Object with public methods
    // open and close mapped to private methods equivalent
    return {
        open,
        close,
    };
}());
