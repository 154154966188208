ACC.cartresendemailverificationaction = {

    _autoload: [
        ['bindResendEmailAction', $('.js-resend-email-button').length > 0],
    ],

    bindResendEmailAction() {
        const button = $('.js-resend-email-button');

        button.on('click', (e) => {
            e.preventDefault();

            const actionUrl = button.attr('data-action-url');
            // eslint-disable-next-line no-unused-vars
            const checkStatusUrl = button.attr('data-check-status-url');
            const popupHref = button.attr('data-source-href');
            const popupTitle = button.attr('data-modal-title');

            $.post({
                url: actionUrl,
                // eslint-disable-next-line no-unused-vars
                complete(data) {
                    $modal.open(null, {
                        title: popupTitle,
                        href: popupHref,
                        inline: popupHref.startsWith('#'),
                        escKey: true,
                        overlayClose: true,
                        closeButton: true,
                        trapFocus: false,

                    });
                },
            });
        });
    },
};

// [Sep 08, 2021] Removed as unused and as failing babel pipeline
// $(document).ready(function ()
// {
// with (ACC.cartresendemailverificationaction)
// {

// }
// });
