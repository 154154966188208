// eslint-disable-next-line no-unused-vars
const Sanitizer = (function () {
    // Private methods, available only inside this module
    const matcher = /<\/?([a-zA-Z0-9]+)*(.*?)\/?>/igm;

    const whitelist = [
        'pre',
        'address',
        'em',
        'hr',
    ];

    // Return public methods and allow them accexible outisde
    return {

        sanitize(html, useWhitelist) {
            html = String(html) || '';
            const matches = this.match(html);

            matches.forEach((tag) => {
                if (!useWhitelist || whitelist.indexOf(tag.name) === -1) {
                    html = html.replace(tag.full, '');
                }
            });

            return html;
        },

        match(html) {
            html = String(html) || '';

            const matches = [];
            let match;

            // eslint-disable-next-line no-cond-assign
            while ((match = matcher.exec(html)) != null) {
                const attrr = match[2].split(' ');
                const attrs = [];

                // extract attributes from the tag
                attrr.shift();
                attrr.forEach((attr) => {
                    attr = attr.split('=');

                    const attr_name = attr[0];
                    let attr_val = attr.length > 1 ? attr.slice(1).join('=') : null;

                    // remove quotes from attributes
                    if (attr_val && attr_val.charAt(0).match(/'|"/)) attr_val = attr_val.slice(1);

                    if (attr_val && attr_val.charAt(attr_val.length - 1).match(/'|"/)) attr_val = attr_val.slice(0, -1);

                    attr = {
                        name: attr_name,
                        value: attr_val,
                    };

                    if (!attr.value) delete attr.value;

                    if (attr.name) attrs.push(attr);
                });

                const tag = {
                    full: match[0],
                    name: match[1],
                    attr: attrs,
                };

                matches.push(tag);
            }

            return matches;
        },

    };
}());
