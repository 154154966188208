/*  $magic-box.js

    Purpose: Magic Box component in Program Details
    section of program page(s)
    ================================================== */
(function magic_box() {
    if ($('.magic-box').length > 0) {
        const mb = $('.magic-box');
        const mb_media = mb.find('.magic-box__media');

        // owl carousel mods
        // eslint-disable-next-line no-var,vars-on-top
        var carousel_thumbnav = function () {
            const thumbnav = mb_media.find('.js-gallery-carousel');
            const thumbnav_item = thumbnav.find('a.item');

            // provide unique class
            thumbnav_item.addClass('thumbnav-item');

            // set first item to active
            thumbnav_item
                .first()
                .addClass('thumbnav-item--active');

            // replace lib href='#' with img src URL
            thumbnav_item.each(function () {
                const thumbnavImgSrc = $(this).find('> img').attr('src');
                $(this).attr('href', thumbnavImgSrc);
            });

            // set active class
            $(document).on('click', '.thumbnav-item', function (event) {
                event.preventDefault();

                $(this)
                    .parents('.owl-wrapper-outer')
                    .find('.thumbnav-item--active')
                    .removeClass('thumbnav-item--active')
                    .end()
                    .end()
                    .addClass('thumbnav-item--active');
            });

            // IE11 lack of support for object-fit
            if (document.querySelector('body').style.objectFit === undefined) {
                thumbnav_item.each(function () {
                    const el = $(this);
                    const image = el.attr('href');

                    el
                        .addClass('object-fit--false')
                        .css({
                            'background-image': `url(${image})`,
                        })
                        .find('img').remove();
                });
            }
        };// end carousel_thumbnav

        // create modals
        // eslint-disable-next-line no-var,vars-on-top
        var mb_modals = function () {
            const modal_img_expander = $('.magic-box__img-expander');
            const active_media_src = $('.magic-box__media')
                .find('.js-gallery-image')
                .find('.owl-item.active')
                .find('img.lazyOwl')
                .attr('data-superzoom-src');
            const modalStageImgContainer = $('.magic-box__media.modal-gallery').find('.js-gallery-image .item > div');

            // set expander href
            modal_img_expander.attr('href', active_media_src);

            // set index for twin magic box in modal
            $(document).on('click', '.magic-box__img-expander', (e) => {
                e.preventDefault();

                $modal.open(null, {
                    inline: true,
                    className: 'colorbox--modal-gallery',
                    href: '#magic-box--media__modal-gallery',
                    onOpen: {},
                    // eslint-disable-next-line no-use-before-define
                    onComplete: onModalOpen,
                });

                function onModalOpen() {
                    $('#colorbox.isHidden').removeClass('isHidden');

                    $('#colorbox .js-gallery-image').owlCarousel({
                        singleItem: true,
                        autoHeight: true,
                        pagination: false,
                        navigation: false,
                        mouseDrag: false,
                        touchDrag: false,
                        addClassActive: true,
                        afterMove: true,
                        lazyLoad: true,
                    });

                    const dialogContainer = $('#colorbox:visible');
                    const dialogContainerWrap = dialogContainer.find('#cboxWrapper');

                    // container: tag class + set aria
                    dialogContainer.attr('aria-modal', 'true');

                    // inner wrap: tag class
                    dialogContainerWrap.addClass('colorbox--modal-gallery__wrapper');

                    $('#cboxClose').text('');
                }
            });

            // IE11 lack of support for object-fit
            if (document.querySelector('body').style.objectFit === undefined) {
                modalStageImgContainer.each(function () {
                    const el = $(this);
                    const image = el.find('> img');
                    // images are lazy loaded but first uses src attribute
                    const imageSrc = image.attr('data-src') || image.attr('src');

                    el
                        .addClass('object-fit--false')
                        .css({
                            'background-image': `url(${imageSrc})`,
                        })
                        .find('img').remove();
                });
            }
        };

        // close dialog if click event occurs outside
        // close the menu if clicked elsewhere
        $(document).mouseup((event) => {
            const modalContent = $('#cboxLoadedContent');

            if (modalContent.length) {
                // if the target of the click isn't the container...
                if (!modalContent.is(event.target) && modalContent.has(event.target).length === 0) {
                    $.colorbox.close();
                }
            }
        });
    }// end if .magic-box

    $(window).on('load', () => {
        if ($('.js-gallery').length > 0) {
            // eslint-disable-next-line block-scoped-var
            carousel_thumbnav();
        }
        if ($('.magic-box__media').length > 0) {
            // eslint-disable-next-line block-scoped-var
            mb_modals();
        }
    });
}());// end magic_box()
