ACC.utils = {
    getThemeName() {
        const body = document.getElementsByTagName('body')[0];
        let themeName = '';

        if (body && body.hasAttribute('data-themename')) {
            themeName = body.getAttribute('data-themename');
        }

        return themeName;
    },

    isHedTheme() {
        return ACC.config.isHedThemeEnabled;
    },

    isLearnerTheme() {
        return ACC.utils.getThemeName() === 'theme-learner';
    },

    isMojoTheme() {
        return ACC.utils.getThemeName() === 'theme-mojo';
    },

    preselectValue(selectBox) {
        const selectBoxOptions = `${selectBox} option`;
        if ($(selectBoxOptions).length === 2) {
            $(selectBoxOptions)[0].removeAttribute('selected');
            $(selectBoxOptions)[1].setAttribute('selected', true);
            $(selectBox).addClass('read-only not-allowed');
            $(selectBox).parent('.c-select-wrapper').addClass('disabled cursor-not-allowed');
        }
    },

    setPlaceholderPostcodeValue() {
        const defaultPostcode = '0000';
        const selectedCountry = $('#registerNewCompanyMiniForm\\.orgAddressCountrySelector').val();
        const postalCodeInput = $('#registerNewCompanyMiniForm\\.postcode');
        const messageKey = 'input.minimum.length';
        if (ACC.config.countriesWith7DigitsPostalCode.includes(selectedCountry)) {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 7)
        } else if (ACC.config.countriesWith6DigitsPostalCode.includes(selectedCountry)) {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 6)
        } else if (ACC.config.countriesWith5DigitsPostalCode.includes(selectedCountry)) {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 5)
        } else if (ACC.config.countriesWith4DigitsPostalCode.includes(selectedCountry)) {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 4)
        } else if (ACC.config.countriesWith2DigitsPostalCode.includes(selectedCountry)) {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 2)
        } else {
            ACC.globalmessages.getGlobalMessageWithParam(postalCodeInput, messageKey, 3)
        }

        if (ACC.config.countriesMissingPostalCode.indexOf(selectedCountry) >= 0) {
            postalCodeInput.val(defaultPostcode);
            postalCodeInput.attr('readonly', true);
            $('#registerNewCompanyPostcode').addClass('hidden');
        } else {
            const postalCodeValue = postalCodeInput.val();
            postalCodeInput.val(postalCodeValue === defaultPostcode ? "" : postalCodeValue);
            postalCodeInput.attr('readonly', false);
            $('#registerNewCompanyPostcode').removeClass('hidden');
        }
    },

    addClassToBodyTag(className) {
        document.body.classList.add(className);
    },

    addModalFocusTrap() {
        const colorBoxModal = document.querySelectorAll('#colorbox')[0];
        const cboxContent = colorBoxModal.querySelector('#cboxContent');
        const modalCloseBtn = colorBoxModal.querySelector('#cboxClose');
        // eslint-disable-next-line no-undef
        const keyboardTrap = new PearsonKeyboardTrap(cboxContent);
        const focusableElements = keyboardTrap.getFocusableEls(document.querySelector('#cboxLoadedContent'));
        const noFocusableElem = (focusableElements.length === 0);

        // set focus for close btn or first focusable element
        if (!noFocusableElem) {
            focusableElements[0].focus();
        } else {
            modalCloseBtn.focus();
        }
    },

    setFocusOnElementById(elementId) {
        if (elementId) {
            const element = document.getElementById(elementId);
            element.focus();
            if (element.tagName.toLowerCase() === 'input') {
                // move the cursor to the end of input text
                element.setSelectionRange(-1, -1);
            }
        }
    },
};
